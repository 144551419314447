import React, { useEffect, useContext } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { components } from 'cng-web-lib'
import { Grid, Typography } from '@material-ui/core'
import { A6TranslationContext } from '../contexts/A6TranslationContext'
import CngSection from 'src/components/cngcomponents/CngSection'


const {
    form: {
        field: {
            CngTextField,
            CngSwitchField,
            CngCountryAutocompleteField,
            CngCodeMasterAutocompleteField
        }
    },
    CngGridItem,
} = components


function A6HeaderSection(props) {
    const { disabled, shouldHideMap } = props
    const { setValue } = useFormContext();
    const { getTranslatedText } = useContext(A6TranslationContext)

    const containerShipment = useWatch({ name: "containerShipment" })


    useEffect(() => {
        if (!containerShipment) {
            setValue("taciOcnA6Container", [])
        }
    }, [containerShipment]);

    function setvaluesOnProc(event) {
        if (event !== '' && event !== null) {
            if (event === '22') {
                setValue('arrivalPort', '')
                setValue('arrivalTerminal', '')
                setValue('arrivalPier', '')
                setValue('eta', '')
            }
            if (event === '21' || event === '22') {
                setValue('customsOfficeOfExit', '')
                setValue('portOfDischarge', '')
            }
        }
    }

    return (
        <Grid item xs={12}>
            <CngSection title={getTranslatedText('A6', 'header')}
                subheader={
                    <Typography color='textSecondary' variant='caption'>
                        <Typography variant='inherit' color='error'>*</Typography>
                        Mandatory fields
                    </Typography>
                }>
                <Grid container spacing={1}>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carrierCode}>
                        <CngTextField
                            name='carrierCode'
                            label={getTranslatedText('A6', 'carrierCode')}
                            inputProps={{ maxLength: 4 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('carrierCode', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselName}>
                        <CngTextField
                            name='vesselName'
                            label={getTranslatedText('A6', 'vesselName')}
                            inputProps={{ maxLength: 28 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('vesselName', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.vesselCode}>
                        <CngTextField
                            name='vesselCode'
                            label={getTranslatedText('A6', 'vesselCode')}
                            inputProps={{ maxLength: 8 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('vesselCode', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.voyageNo}>
                        <CngTextField
                            name='voyageNo'
                            label={getTranslatedText('A6', 'voyageNo')}
                            inputProps={{ maxLength: 10 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('voyageNo', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.nationality}>
                        <CngCountryAutocompleteField
                            name='nationality'
                            label={getTranslatedText('A6', 'nationality')}
                            disabled={disabled}
                            lookupProps={{ label: (record) => (`${record.code};${record.descriptionEn}`) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.masterOperator}>
                        <CngTextField
                            name='masterOperator'
                            label={getTranslatedText('A6', 'masterOperator')}
                            inputProps={{ maxLength: 35 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('masterOperator', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.convRefno}>
                        <CngTextField
                            name='convRefno'
                            label={getTranslatedText('A6', 'convRefno')}
                            inputProps={{ maxLength: 25 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('convRefno', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.crew}>
                        <CngTextField
                            name='crew'
                            label={getTranslatedText('A6', 'crew')}
                            inputProps={{ maxLength: 4 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('crew', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.passengers}>
                        <CngTextField
                            name='passengers'
                            label={getTranslatedText('A6', 'passengers')}
                            inputProps={{ maxLength: 4 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('passengers', e.target.value.toUpperCase()) }}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyanceType}>
                        <CngCodeMasterAutocompleteField
                            name='conveyanceType'
                            label={getTranslatedText('A6', 'conveyanceType')}
                            disabled={disabled}
                            codeType='CONVEYANCE_TYPE'
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.containerShipment}>
                        <CngSwitchField
                            name='containerShipment'
                            label={getTranslatedText('A6', 'containerShipment')}
                            disabled={disabled}
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.customsProc}>
                        <CngCodeMasterAutocompleteField
                            name='customsProc'
                            label={getTranslatedText('A6', 'customsProc')}
                            disabled={disabled}
                            codeType='A6_CUSTOM_PROCEDURE'
                            onChange={setvaluesOnProc}
                            required
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.charterInfo}>
                        <CngCodeMasterAutocompleteField
                            name='charterInfo'
                            label={getTranslatedText('A6', 'charterInfo')}
                            disabled={disabled}
                            codeType='A6_CHARTER_INFO'
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.specialops}>
                        <CngTextField
                            name='specialops'
                            label={getTranslatedText('A6', 'specialops')}
                            inputProps={{ maxLength: 30 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('specialops', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                    <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.cargoDescription}>
                        <CngTextField
                            name='cargoDescription'
                            label={getTranslatedText('A6', 'cargoDescription')}
                            inputProps={{ maxLength: 50 }}
                            disabled={disabled}
                            onBlur={(e) => { setValue('cargoDescription', e.target.value.toUpperCase()) }}
                            size='small'
                        />
                    </CngGridItem>
                </Grid>
            </CngSection>
        </Grid>
    )
}

export default A6HeaderSection